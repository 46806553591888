import { GenericAction } from '../../../../common/state/models/generic-action.model';
import { RoleActionTypes } from '../actions/role-action-types';
import { IRoleState, RoleState, IRolePermission} from '../models/role.model';
import { errorListData, receiveListData, requestListData } from 'src/app/common/utils/reducer/reducer.utils';

export function roleReducer(
  lastState: IRoleState = new RoleState(),
  action: GenericAction<RoleActionTypes, any>
): IRoleState {
  switch (action.type) {

    case RoleActionTypes.REQUEST_PERMISSION_LIST_ROLE:
      return requestListData<IRoleState, IRolePermission[]>(lastState, ['permissionList']);
    case RoleActionTypes.RECEIVE_PERMISSION_LIST_ROLE:
      return receiveListData<IRoleState, IRolePermission[]>(lastState, ['permissionList'], action.payload);
    case RoleActionTypes.ERROR_RECEIVE_PERMISSION_LIST_ROLE:
      return errorListData<IRoleState, IRolePermission[]>(lastState, ['permissionList'], action.payload);

    default:
      return lastState;
  }
}
