import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IRolePermission } from '../models/role.model';
import { RoleActionTypes } from './role-action-types';

export class RequestPermissionListForRole implements Action {
  readonly type: RoleActionTypes.REQUEST_PERMISSION_LIST_ROLE = RoleActionTypes.REQUEST_PERMISSION_LIST_ROLE;
}

export class ReceivePermissionListForRole implements Action {
  readonly type: RoleActionTypes.RECEIVE_PERMISSION_LIST_ROLE = RoleActionTypes.RECEIVE_PERMISSION_LIST_ROLE;

  constructor(public payload: IRolePermission[]) { }
}

export class ErrorReceivePermissionListForRole implements Action {
  readonly type: RoleActionTypes.ERROR_RECEIVE_PERMISSION_LIST_ROLE = RoleActionTypes.ERROR_RECEIVE_PERMISSION_LIST_ROLE;

  constructor(public payload: HttpErrorResponse) { }
}

