<div
  class="uib-actionbar"
  role="group"
  *ngIf="router.url?.includes('user-management')"
>
  <button
    *ngIf="!selectedItem"
    type="button"
    class="uib-button uib-button--action"
    (click)="onCreateClick($event)"
    [disabled]="router.url?.includes('add')"
    title="Add User"
  >
    <uib-icon size="dynamic" name="uib-icon--new"></uib-icon>
  </button>

  <button
    *ngIf="selectedItem"
    [disabled]="
      !selectedItem || router.url?.includes('update') || isDeletePopupShown
    "
    (click)="onDeleteClick($event)"
    type="button"
    class="uib-button uib-button--action"
    title="Delete User"
  >
    <uib-icon size="dynamic" name="uib-icon--delete"></uib-icon>
  </button>

  <button
    *ngIf="selectedItem"
    [disabled]="
      !selectedItem || router.url?.includes('update') || isDeletePopupShown
    "
    (click)="onEditClick($event)"
    type="button"
    class="uib-button uib-button--action"
    title="Update User"
  >
    <uib-icon size="dynamic" name="uib-icon--edit"></uib-icon>
  </button>
</div>

<div
  class="uib-actionbar"
  role="group"
  *ngIf="router.url?.includes('companies')"
>
  <button
    *ngIf="!selectedItem"
    type="button"
    class="uib-button uib-button--action"
    (click)="onCreateClick($event)"
    [disabled]="router.url?.includes('add')"
    title="Add company"
  >
    <uib-icon size="dynamic" name="uib-icon--new"></uib-icon>
  </button>

  <button
    *ngIf="selectedItem"
    [disabled]="
      !selectedItem || router.url?.includes('update') || isDeletePopupShown
    "
    (click)="onDeleteClick($event)"
    type="button"
    class="uib-button uib-button--action"
    title="Delete"
  >
    <uib-icon size="dynamic" name="uib-icon--delete"></uib-icon>
  </button>

  <button
    *ngIf="selectedItem"
    [disabled]="
      !selectedItem || router.url?.includes('update') || isDeletePopupShown
    "
    (click)="onEditClick($event)"
    type="button"
    class="uib-button uib-button--action"
    title="Update"
  >
    <uib-icon size="dynamic" name="uib-icon--edit"></uib-icon>
  </button>
</div>

<div
  class="uib-actionbar"
  role="group"
  *ngIf="router.url?.includes('test-data-drugcheck')"
>
  <button
    *ngIf="selectedItem"
    (click)="onViewDetailsClick($event)"
    type="button"
    class="uib-button uib-button--action"
    title="View Drugcheck"
  >
    <uib-icon size="dynamic" name="uib-icon--view"></uib-icon>
  </button>
  <button
    *ngIf="showDownloadExcel()"
    (click)="onDownloadExcel($event)"
    type="button"
    class="uib-button uib-button--action"
    title="Download Excel"
    [disabled]="isDownloadingExcel"
  >
    <uib-icon
      *ngIf="!isDownloadingExcel"
      size="dynamic"
      name="uib-icon--file-excel"
    ></uib-icon>
    <div *ngIf="isDownloadingExcel" class="uib-board uib-board--centered">
      <div class="spinner-border spinner" role="status"></div>
    </div>
  </button>
</div>

<div
  class="uib-actionbar"
  role="group"
  *ngIf="router.url?.includes('test-data-alcotest')"
>
  <button
    *ngIf="
      selectedItem?.has_workflow_data || selectedItem?.completed_workflow_id
    "
    (click)="onViewDetailsClick($event)"
    type="button"
    class="uib-button uib-button--action"
    title="View Alcotest"
  >
    <uib-icon size="dynamic" name="uib-icon--view"></uib-icon>
  </button>
  <button
    *ngIf="showDownloadExcel()"
    (click)="onDownloadExcel($event)"
    type="button"
    class="uib-button uib-button--action"
    title="Download Excel"
    [disabled]="isDownloadingExcel"
  >
    <uib-icon
      *ngIf="!isDownloadingExcel"
      size="dynamic"
      name="uib-icon--file-excel"
    ></uib-icon>
    <div *ngIf="isDownloadingExcel" class="uib-board uib-board--centered">
      <div class="spinner-border spinner" role="status"></div>
    </div>
  </button>
</div>

<div
  class="uib-actionbar"
  role="group"
  *ngIf="router.url?.includes('active-devices')"
>
  <button
    *ngIf="showDownloadExcel()"
    (click)="onDownloadExcel($event)"
    type="button"
    class="uib-button uib-button--action"
    title="Download Excel"
    [disabled]="isDownloadingExcel"
  >
    <uib-icon
      *ngIf="!isDownloadingExcel"
      size="dynamic"
      name="uib-icon--file-excel"
    ></uib-icon>
    <div *ngIf="isDownloadingExcel" class="uib-board uib-board--centered">
      <div class="spinner-border spinner" role="status"></div>
    </div>
  </button>
</div>
