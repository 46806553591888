import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { DropdownService } from './../../services/dropdown/dropdown.service';
import { TableConstants, TableFilterType } from '../../constants/table.constants';
import { ITableColumn } from '../../models/table.model';
import { TableService } from '../../services/table/table.service';

@Component({
  selector: 'draeger-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.sass'],
})
export class TableComponent implements OnChanges, AfterViewInit {

  @ViewChild('draegerTable')
  public draegerTable: Table | any;

  @Input() tableColumns: ITableColumn[];
  @Input() dataTable: any;
  @Input() scrollable: boolean = true;

  @Input() datePickerFormat: string;
  @Input() datePickerSelectionMode: string;
  @Input() datePickerShowTime: boolean = false;
  @Input() datePickerShowSeconds: boolean = false;
  @Input() readOnlyDatePicker: boolean = false;

  @Input() multiSelectOptions: any;
  copyMultiSelectOptions: readonly any[];
  @Input() multiSelectActiveOption: any;
  @Input() dropdownActiveOption: any;
  @Input() defaultRange: any;
  searchedItem: string;

  @Input() customFilterLayout: string[];

  @Input() customColumnsLayout: string[];
  @Input() customColumnsRef: TemplateRef<any[]>;
  @Input() columnResizeMode: string = 'fit';
  @Input() displayFilters: boolean = false;
  @Input() lazy: boolean = false;
  @Input() paginator: boolean = false;
  @Input() rows: number = 25;
  @Input() first: number = 0;
  @Input() filterDelay: number = 600;
  @Input() rowsPerPageOptions: number[] = TableConstants.rowsPerPageOptions;
  @Input() totalRecords: number;
  @Input() isLoading: boolean = false;
  @Input() savedFiltersName: string;
  @Input() routeUrl: string;
  @Input() disableTooltip: boolean;
  @Input() selectedCoordinates: string | any;

  @Output() handleTableRef: EventEmitter<any> = new EventEmitter();
  @Output() handleRowSelection: EventEmitter<any> = new EventEmitter();
  @Output() handleOnSortChange: EventEmitter<any> = new EventEmitter();
  @Output() handleColumnVisibility: EventEmitter<ITableColumn[]> = new EventEmitter();
  @Output() handleOnPageChange: EventEmitter<{ pageNumber: number; rows: number }> = new EventEmitter();
  @Output() handleFilterValue: EventEmitter<any> = new EventEmitter();
  @Output() handleOnLazyLoading: EventEmitter<LazyLoadEvent> = new EventEmitter();

  @Input() sortColumn: any;
  selectedData: any;
  @Input() pageNumber: number = 0;
  loadDataEvent: LazyLoadEvent;
  filtersData: any;

  lastTableElement: any;

  filterType: any = TableFilterType;

  constructor(
    public tableService: TableService,
    public dropdownService: DropdownService,
    private router: Router,
  ) { }

  get visibleColumns(): ITableColumn[] {
    if (this.tableColumns) {
      return this.tableColumns.filter((col: ITableColumn) => col.visible);
    }
  }

  get tableStoredFilters(): any {
    return localStorage.getItem(this.savedFiltersName) ? JSON.parse(localStorage.getItem(this.savedFiltersName)) : null;
  }

  ngOnChanges(changes: SimpleChanges | any): void {
    if (this.tableColumns && this.dataTable) {

      const mutableDataTable: any = JSON.parse(JSON.stringify(this.dataTable));

      mutableDataTable.forEach((data: any) => {
        this.tableColumns.forEach((column: ITableColumn) => {
          data[column.field] = _.get(data, column.field);
        });
      });

      this.dataTable = mutableDataTable;
      this.lastTableElement = this.tableService.lastTableHeaderElem(this.tableColumns);
    }

    if (changes.multiSelectOptions && changes.multiSelectOptions.currentValue) {
      this.copyMultiSelectOptions = Object.freeze(this.multiSelectOptions);
    }
  }

  ngAfterViewInit(): void {
    this.handleTableRef.emit(this.draegerTable);
    this.tableService.arrangePaginingOfElement(this.draegerTable);

    setTimeout(() => {
      this.setSelectedRow();
    },);
  }

  onSelect(column: any): void {
    if (column) {
      if (this.selectedData?.id === column?.id) {
        this.selectedData = null;
        this.handleRowSelection.emit(null);
      } else {
        this.selectedData = column;
        this.handleRowSelection.emit(column);
      }
    }
  }

  setSelectedRow() {
    if (localStorage.hasOwnProperty('selectedTestDataDrugCheckDetails')) {
      const item = JSON.parse(localStorage.getItem('selectedTestDataDrugCheckDetails'));

      this.selectedData = item;
      this.handleRowSelection.emit(item);
      localStorage.removeItem('selectedTestDataDrugCheckDetails');
    } else if (localStorage.hasOwnProperty('selectedTestDataAlcotestDetails')) {
      const item = JSON.parse(localStorage.getItem('selectedTestDataAlcotestDetails'));

      this.selectedData = item;
      this.handleRowSelection.emit(item);
      localStorage.removeItem('selectedTestDataAlcotestDetails');
    }
  }

  removeSelectedRow(): void {
    if (!this.router.url.includes(this.routeUrl)) {
      this.onSelect(null);
    }
  }

  changeSortIcon(event: any): void {
    this.sortColumn = event;
    this.first = 0;
    this.pageNumber = 0;
    this.draegerTable.first = 0;
    this.handleOnSortChange.emit(this.pageNumber);
  }

  onPageOrRowChange(event: { first: number; rows: number }): void {
    this.first = event.first;
    this.rows = event.rows;

    this.pageNumber = event.first / event.rows;
    this.handleOnPageChange.emit({
      pageNumber: this.pageNumber,
      rows: this.rows
    });
  }

  onLazyLoadingData(event: LazyLoadEvent): void {
    /* istanbul ignore if */
    if (localStorage.hasOwnProperty('testDataDrugCheckTableFilters')) {
      let eventFromLocalStorage = {};
      const params = JSON.parse(localStorage.getItem('testDataDrugCheckTableFilters'));

      eventFromLocalStorage = {
        first: params.first,
        rows: params.rows,
        sortOrder: params.order,
        sortField: params.sortField,
      };

      setTimeout(() => {
        this.first = params.first;
        this.rows = params.rows;
        this.sortColumn = {
          field: params.field,
          order: params.order
        };
      });

      this.loadDataEvent = eventFromLocalStorage;
    } else if (localStorage.hasOwnProperty('testDataAlcotestTableFilters')) {
      let eventFromLocalStorage = {};
      const params = JSON.parse(localStorage.getItem('testDataAlcotestTableFilters'));

      eventFromLocalStorage = {
        first: params.first,
        rows: params.rows,
        sortOrder: params.order,
        sortField: params.sortField,
      };

      setTimeout(() => {
        this.first = params.first;
        this.rows = params.rows
        this.sortColumn = {
          field: params.field,
          order: params.order
        };
      });

      this.loadDataEvent = eventFromLocalStorage;
    } else {
      this.loadDataEvent = event;
    }

    this.handleOnLazyLoading.emit(this.loadDataEvent);

    if (event.rows !== this.rows) {
      this.draegerTable._first = 0;
    }
  }

  onTextFilterChange(value: any, field: string): void {
    this.handleFilterValue.emit({ [field]: value });
  }

  onDateFilterSelect(event: Date[], field: string): void {
    this.handleFilterValue.emit({ [field]: event });
  }

  onDateFilterChange(field: string): void {
    this.handleFilterValue.emit({ [field]: 'reset-date' });
  }

  onMultiSelectChange(event: any[], field: string): void {
    this.handleFilterValue.emit({ [field]: event });
  }

  onMultiSelectRemoveSelectedItems(field: string): void {
    this.multiSelectOptions = this.copyMultiSelectOptions;
    this.searchedItem = null;
    this.multiSelectActiveOption = [];
    this.handleFilterValue.emit({ [field]: [] });
  }

  onDropdownChange(event: any, field: string): void {
    this.handleFilterValue.emit({ [field]: event.value });
  }

  onLocationChange(event: any, field: string) {
    this.selectedCoordinates = event;
    this.handleFilterValue.emit({ [field]: event });
  }

  tooltipText(data: any) {
    return data?.join(' ; ')
  }
}
