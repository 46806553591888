
import { IStoreApiList, StoreApiList } from '../../../../common/models/store-api-list.model';

export interface IRoleState {
  permissionList: IStoreApiList<IRolePermission[]>;
}

export class RoleState implements IRoleState {
  permissionList: IStoreApiList<IRolePermission[]> = new StoreApiList();
}

export interface IRole {
  id: string;
  name?: string;
  localizedName?: string;
}

export class Role implements IRole {
  id: string;
  name: string;

  constructor();
  constructor(role: IRole);
  constructor(role?: IRole) {
    if (!role) {
      this.name = null;
    } else {
      this.name = role.name
    }
  }
}

export interface IRolePermission {
  permission: string,
  enabled?: boolean,
  label?: string;
  localizedName?: string;
}

export class RolePermission implements IRolePermission {
  permission: string;
  enabled: boolean;
  label?: string;
  localizedName?: string;

  constructor();
  constructor(permission: IRolePermission);
  constructor(permission?: IRolePermission) {
    if (!permission) {
      this.permission = null;
      this.enabled = null;
    } else {
      this.enabled = permission.enabled;
      this.permission = permission.permission;
    }
  }
}

