import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHelper } from '../../../common/services/http-helper/http-helper';
import { IRolePermission } from '../../state/role-management/models/role.model';

@Injectable()
export class RoleService {
  private baseUrl: string = environment.API_URLS.BASE;

  constructor(private httpClient: HttpClient, private httpHelper: HttpHelper) { }

  public getPermissionList(): Observable<IRolePermission[] | HttpErrorResponse> {
    return this.httpClient.get<Array<IRolePermission>>(`${this.baseUrl}users/perms/`, this.httpHelper.options);
  }
}
