import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IRoleState } from '../models/role.model';
import { RequestPermissionListForRole } from './role-action-types-creators';

@Injectable({ providedIn: 'root' })
export class RoleActions {
  constructor(private store: Store<IRoleState>) { }
  public requestPermissionListForRole(): void {
    this.store.dispatch(new RequestPermissionListForRole());
  }

}
