import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { IRolePermission, IRoleState } from '../models/role.model';
import { IStoreApiList } from '../../../../common/models/store-api-list.model';
import { ApplicationState, IApplicationState } from '../../../../common/state/models/app.state.model';

export const roleState = (state: IApplicationState) => state.role;

export const selectPermissionList: MemoizedSelector<
  ApplicationState,
  IStoreApiList<IRolePermission[]>,
  DefaultProjectorFn<IStoreApiList<IRolePermission[]>>
> = createSelector(roleState, (state: IRoleState) => state.permissionList);
