
import { Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { RoleActions } from '../../state/role-management/actions/role.actions';
import { CommonConstants, IApplicationState } from 'src/app/common';
import { IRolePermission } from '../../state/role-management/models/role.model';
import { selectPermissionList } from '../../state/role-management/selectors/role.selector';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ThemingService } from '@odx/angular/theming'
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { CustomUserClaims, UserClaims } from '@okta/okta-auth-js';

@Component({
  selector: 'draeger-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent extends OnDestroyMixin() implements OnInit, OnDestroy {
  protected readonly themingService = inject(ThemingService);
  isIframe: boolean = false;
  userLoggedIn: boolean = false;
  permissions: IRolePermission[];
  userUnauthorized: boolean = true;
  isLoading: boolean = true;
  isMapLoaded: boolean;
  private readonly _destroying$: Subject<void> = new Subject<void>();

  constructor(
    private roleActions: RoleActions,
    private store: Store<IApplicationState>,
    public router: Router,
    private translate: TranslateService,
    private _oktaStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth
  ) {
    super();
    this.translate.addLangs(Object.values(CommonConstants.supportedLanguages));
    this.themingService.enableDarkMode()
  }

  ngOnInit(): void {
    this.translate.use(localStorage.getItem('localizationLanguage') || 'en-GB');
    this.isIframe = window !== window.parent && !window.opener;

    this._oktaStateService.authState$.pipe(
      filter((isAuthenticated) => !!isAuthenticated?.isAuthenticated),
      takeUntil(this._destroying$)
    ).subscribe(() => {
      if (this.userLoggedIn !== true) {
        this._oktaAuth.getUser().then((user: UserClaims<CustomUserClaims>) => {
          this.requestPermissionList();
          this.getPermissionList()
          this.userLoggedIn = user ? true : false;
        });
      }
    });

    setTimeout(() => {
      if (this.userLoggedIn !== true) this.isLoading = false;
    }, 1000);
  }

  requestPermissionList(): void {
    this.roleActions.requestPermissionListForRole();
  }

  getPermissionList(): void {
    this.store.pipe(
      select(selectPermissionList),
      takeUntil(this.destroy)
    ).subscribe((state: any) => {
      this.isLoading = state.isLoading;
      if (state.data) {
        this.permissions = state.data;
        this.userUnauthorized = false;
      }

      this.permissions?.forEach((perm: IRolePermission) => {
        if (Object.values(perm).includes('has_web_access')) {
          if (!perm.enabled) {
            this.logout("user_not_allowed");
          }
        }
      });

      if (state.errors && (state?.errors?.error?.detail === 'user_account_disabled' || state?.errors?.error?.detail === "no_user_account_found" || state?.errors?.error?.detail === "Error decoding signature." || state?.errors?.error?.detail === "Signature has expired.")) {
        this.logout(state?.errors?.error?.detail);
      }
    });
  }

  async logout(error: string) {
    await this._oktaAuth.signOut().then(() => {
      localStorage.clear();
      localStorage.setItem(CommonConstants.LOGIN_ERROR, error ? error : 'try_again');
      this.userUnauthorized = true;
      this.isLoading = true;
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
