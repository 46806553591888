export const environment = {
  production: false,

  API_URLS: {
    BASE: $ENV.BASE_URL,
    VERSION: $ENV.VERSION,
    ENV: $ENV.ENV
  },

  OKTA: {
    CLIENT_ID: $ENV.OKTA_CLIENT_ID,
    ISSUER: $ENV.OKTA_ISSUER,
  },

  GOOGLE_MAP: {
    GOOGLE_API_KEY: $ENV.GOOGLE_API_KEY
  },

  IS_CANADA_INSTANCE: $ENV.IS_CANADA_INSTANCE
};
